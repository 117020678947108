@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.form-control{
  background-color: inherit;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', "Amarante";

}

a{
  color: inherit;
  text-decoration: none;
}