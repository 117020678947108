.myprofile {
    background: linear-gradient(180deg, #614385 22.04%, #516395 76.12%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    h4 {
      padding: 5px 10px;
      box-shadow: 0px 4px 35px 10px #00000040;
      border-radius: 15px;
    }
  
    .select {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 20px;
  
      select {
        padding: 5px 10px;
        box-shadow: 0px 4px 35px 10px #f8f6f640;
        border-radius: 15px;
        background: rgb(204, 203, 203);
        color: rgb(11, 11, 11);
        border: none;
        width: 170px;
  
        option {
          color: black;
        }
      }
    }
  
    .table-responsive {
      overflow-x: auto;
      box-shadow: 0px 4px 35px 0px #00000040;
      max-width: 800px;
      border-radius: 10px;

      .table {
        background: none;
        box-shadow: none !important;
        thead {
          tr {
            background: none;
            th {
              background: none;
              color: white;
            }
           
          }
        }
  
        tbody {
          tr {
            background: none;
            #back{
              background-color: transparent;
              padding: unset;
              text-decoration: none;
              width: 100%;
              height: 100%;
            }
            th,
            td {
              background: none;
              color: white;
              white-space: nowrap !important;
  
              span {
                display: flex;
                box-shadow: 10px 4px 35px 10px #00000040;
                height: 20px;
                width: 60px;
                font-size: 15px;
                padding: 5px 10px;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                color: white;
                background: linear-gradient(180deg, rgba(57, 30, 30, 0.2) 0%, #1e50cf 100%);
              }
            }
  
            &:last-child {
              border: none;
            }
            &:hover{
                th,td{
                    color: black;
                }
            }
          }
        }
      }
    }
  }
  

  .myprofileactivation {
    background: linear-gradient(180deg, #614385 22.04%, #516395 76.12%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
  }

  .myprofileactivation {
    background: linear-gradient(180deg, #614385 22.04%, #516395 76.12%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
  
    h4 {
      padding: 5px 10px;
      box-shadow: 0px 4px 35px 10px #00000040;
      border-radius: 15px;
    }
  
    .select {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 20px;
  
      select {
        padding: 5px 10px;
        box-shadow: 0px 4px 35px 10px #f8f6f640;
        border-radius: 15px;
        background: rgb(204, 203, 203);
        color: rgb(11, 11, 11);
        border: none;
        width: 170px;
  
        option {
          color: black;
        }
      }
    }
  
    .table-responsive {
      overflow-x: auto;
      box-shadow: 0px 4px 35px 0px #00000040;
      max-width: 800px;
      border-radius: 10px;

      .table {
        background: none;
        box-shadow: none !important;
        thead {
          tr {
            background: none;
            th {
              background: none;
              color: white;
            }
           
          }
        }
  
        tbody {
          tr {
            background: none;
            #back{
              background-color: transparent;
              padding: unset;
              text-decoration: none;
              width: 100%;
              height: 100%;
            }
            th,
            td {
              background: none;
              color: white;
              white-space: nowrap !important;
  
              span {
                display: flex;
                box-shadow: 10px 4px 35px 10px #00000040;
                height: 20px;
                width: 60px;
                font-size: 15px;
                padding: 5px 10px;
                border-radius: 5px;
                align-items: center;
                justify-content: center;
                color: white;
                background: linear-gradient(180deg, rgba(57, 30, 30, 0.2) 0%, #1e50cf 100%);
              }
            }
  
            &:last-child {
              border: none;
            }
            &:hover{
                th,td{
                    color: black;
                }
            }
          }
        }
      }
    }
  }