.register{
    background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 89vh;
    form{
        margin: 20px ;
        width: 700px;
        background: #FFFFFF33;
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        color: white;
        .form-control{
            background: none;
            outline: none;
        }
        button{
            display: flex;
            width: 100%;
            margin-top: 20px;
            background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
            border-radius: 40px;
            height: 65px;
            align-items: center;
            justify-content: center;
            border: none;
        }
        .password{
            display: flex;
            position: relative;
            z-index: 0 !important;
            .openeye{
                position: absolute;
                right: 10px;
                top: 10px;
            }
            .closedeye{
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }
      
    }
    
}

@media(max-width:600px){
    h5{
        font-size: 18px;
        span{
            font-size: 18px;
        }
    }
}
@media(max-width:420px){
    h5{
        font-size: 13px;
        span{
            font-size: 13px;
        }
    }
}