.home{
    background: linear-gradient(135deg, #811753 0%, #0B8FA3 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 89vh;
    position: relative;

    .home-details{
        margin: 20px ;
        width: 600px;
        background: #FFFFFF33;
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .active{
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 300px;
            gap: 100px;
            .zero-class{
                margin-top: 20px;
                background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
                color: white;
                padding: 10px;
                border-radius: 25px;
                font-size: 15px;
                width: 150px;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
        .social-medias{
            display: flex;
            gap: 100px;
            max-width: 600px;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 30px;
            a{
                text-decoration: none;
                color: inherit;
            }

            .social{
                box-shadow: 0px 4px 35px 0px #00000040;
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                align-items: center;
                border-radius: 15px;
                button{
                    border: none;
                    background-color: transparent;
                }

            }
        }
        .profile-links{
            display: flex;
            flex-direction: column;
            gap: 20px;
            .social{
                box-shadow: 0px 4px 35px 0px #00000040;
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                align-items: center;
                border-radius: 15px;
                width: 200px;
                justify-content: center;
            }
            a{
                color: inherit;
                text-decoration: none;
            }
        }
        .profile-linkss{
            display: flex;
            flex-direction: column;
            gap: 20px;
            .social{
                box-shadow: 0px 4px 35px 0px #00000040;
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                align-items: center;
                border-radius: 15px;
                width: 400px;
                justify-content: center;
            }
            a{
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .success-message{
     position: absolute;
     background-color: white;
     padding: 15px;
     top: 20px;
     height: 110px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 5px;
    }
}

@media(max-width:480px){
    .home{
        .home-details{
            width: unset !important;
            margin: unset;
            padding: 20px 20px;
            .active{
                gap: 20px;
                h4{
                    font-size: 20px;
                }
                .zero-class{
                    padding: 5px 10px;
                }
            }
            .social{
                button{
                    span{
                        display: none;
                    }
                }
            }
            .social-medias{
                gap: 30px;
            }
        }
    }
    
}
@media(max-width:375px){
    .home{
        .home-details{
        
            .active{
                gap: 10px;
                h4{
                    font-size: 15px;
                }
                .zero-class{
                    padding: 5px 10px;
                }
            }
            .social{
                button{
                    span{
                        display: none;
                    }
                }
            }
            .social-medias{
                gap: 10px;
                .social{
                    padding: 5px;
                    button{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}