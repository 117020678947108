.register{
    background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    .log-details{
        margin: 20px ;
        width: 500px;
        background: #FFFFFF33;
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        color: white;
        .logo{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
           img{
            width: 70px;
            height: 70px;
           }
        }
            h4{
                text-align: center;
                span{
                    color: rgb(121, 242, 121);
                }
            }
      
        button{
            display: flex;
            width: 100%;
            margin-top: 20px;
            background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
            border-radius: 40px;
            height: 65px;
            align-items: center;
            justify-content: center;
            border: none;
        }  
        .register-details{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .sub-details{
                display: flex;
                justify-content: space-between;
                h5{
                    font-size: 18px;
                }
            }
        }
        a{
            text-decoration: none;
        }
    }
   
}
#register{
    height: unset !important;
}