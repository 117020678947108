#home{
// height: unset !important;
height: 92vh;
}
.home-details{
        margin: 20px ;
        width: 600px;
        background: #FFFFFF33;
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    h4{
        width: 100%;
    }
    .post{
        display: flex;
        gap: 10px;
        background: linear-gradient(90deg, #008767 13.5%, #002119 87%);
        width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        h6{
            margin-bottom: unset;
        }
    }
    .profile-links{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button{
            display: flex;
            width: 150px;
            margin-top: 20px;
            background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
            border-radius: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            border: none;
        }
       .ranks{
        box-shadow: 0px 4px 35px 0px #00000040;
        padding: 20px;
        h6{
            color: black;
        }

       }
    }
}