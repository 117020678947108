.logins{
     background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 89vh;
    form{
        margin: 20px ;
        width: 700px;
        background: #FFFFFF33;
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        color: white;
        h4{
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
        }
    .logo{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        img{
            width: 200px;
            height: 200px;
        }
    }
    .log-inputs{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 50px;
        .password{
            display: flex;
            flex-direction: column;
            position: relative;
            .forgot{
                display: flex;
                justify-content: flex-end;
                
            }
            .openeyes{
                position: absolute;
                right: 10px;
                top: 43px;
            }
            .closedeyes{
                position: absolute;
                right: 10px;
                top: 43px;
            }
            a{
                color: inherit;
                &:hover{
                    color: blue;
                }
            }
        }
    }
    a{
        text-decoration: none;
        
    }
    h6{
        display: flex;
        justify-content: center;
        color: white;
        margin-top: 30px;
    }
    button{
        display: flex;
        width: 100%;
        margin-top: 20px;
        background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
        border-radius: 40px;
        height: 65px;
        align-items: center;
        justify-content: center;
        border: none;
    }
    }
}
