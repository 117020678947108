#reg{
    background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89vh;

    .income{
        margin: 20px ;
        width: 700px;
        background: #FFFFFF33;
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        justify-content: center;
        color: white;
        #income-select{
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;
        }
        .btn{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
           
                button{
                    display: flex;
                    width: 150px;
                    margin-top: 20px;
                    background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
                    border-radius: 20px;
                    height: 40px;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    }
            
           
        }
        .select{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            .dropdown{
              padding: 5px 10px;
              box-shadow: 0px 4px 35px 0px #00000040;
              border-radius: 15px;
              background: none;
              color: white;
              border: none;
              background: none;
              option{
                color: black;
              }
            }
        }
        .income-details{
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            
            .sub-income{
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                h4{
                    font-size: 20px;
                }

                .amount-details{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    .amount{
                        border: 1px solid white;
                        padding: 1px  10px;
                        border-radius: 5px;
                        height: 25px;
                        width: 70px;
                        text-align: center;
                    }
                    .withdraw{
                        background: linear-gradient(180deg, rgba(57, 30, 30, 0.2) 0%, #1E50CF 100%);
                        border: none;
                        padding: 1px 10px;
                        border-radius: 5px;
                        color: white;
                    }
                }
            }
        }

        .withdrawbalance{
            background: linear-gradient(180deg, rgba(57, 30, 30, 0.2) 0%, #1E50CF 100%);
            border: none;
            padding: 1px 22px;
            border-radius: 5px;
            color: white;
        }
        .button-holder{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .button{
                display: flex;
                width: 150px;
                margin-top: 20px;
                background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
                border-radius: 20px;
                height: 40px;
                align-items: center;
                justify-content: center;
                border: none;
                }
        }
       
        
    }
   
}
.card{
    background-color: rgb(73, 70, 70);
    height: 200px;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content:space-between ;
    margin-bottom: 20px;
    p{
        margin-bottom: unset;
    }
}
.checks{
    display: flex;
    gap: 20px;
    .dep{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: aliceblue;
        padding: 10px;
        border-radius: 6px;
        p{
            margin-bottom: unset;
        }
        &:hover{
            background-color: rgb(212, 227, 240);
        }
    }
}

@media(max-width:522px){
    .income{
        margin: unset !important;
        width: unset;
        padding: 10px !important;
        .income-details{  
            .sub-income{
                h4{
                    font-size: 18px;
                }
    
                .amount-details{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    .amount{
                        border: 1px solid white;
                        padding: 1px  10px;
                        border-radius: 5px;
                    }
                    .withdraw{
                        background: linear-gradient(180deg, rgba(57, 30, 30, 0.2) 0%, #1E50CF 100%);
                        border: none;
                        padding: 1px 10px;
                        border-radius: 5px;
                        color: white;
                    }
                }
            }
        }
    }
    
}
@media(max-width:404px){
    .income{
        margin: unset !important;
        width: unset;
        padding: 10px !important;
        .income-details{  
            .sub-income{
                h4{
                    font-size: 13px !important;
                }
    
                .amount-details{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    .amount{
                        border: 1px solid white;
                        padding: 1px  10px;
                        border-radius: 5px;
                        font-size: 15px;
                    }
                    .withdraw{
                        background: linear-gradient(180deg, rgba(57, 30, 30, 0.2) 0%, #1E50CF 100%);
                        border: none;
                        padding: 1px 10px;
                        border-radius: 5px;
                        color: white;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    
}
