.profile-id{
    // background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89vh; 
    flex-direction: column;
    .id-card{
        margin: 20px ;
        width: 350px;
        background: hsla(0, 0%, 100%, 0.962);
        border-radius: 5px;
        padding: 20px 40px;
        box-shadow: 0px 4px 35px 0px #00000040;
        justify-content: center;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 10px 5px 10px;
        background-color: #f0f0f5;
        .id-border{
            background-color: white;
            border-radius: 10px 10px 50px 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: rgb(251, 248, 248);
            gap: 20px;
            padding: 15px;
            background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);

            .logo{
                text-align: center;
                #idLogo{
                    width: 50px;
                    height: 50px;
        
                } 
            }  
            .profile-image{
                width: 100px;
                height: 100px;
                img{
                    width: 100% !important;
                    height: 100% !important;
                    border-radius: 50%;
                    border: 2px solid #363666;
                }
            }
            .profile-details{
                display: flex;
                flex-direction: column;
                margin-left: calc(15%);
                gap: 15px;
                h6{
                    display: flex;
                    gap: 10px;
                }
                
            }
        }
       
    }
    button{
        display: flex;
        margin-top: 20px;
        background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
        border-radius: 40px;
        height: 50px;
        align-items: center;
        justify-content: center;
        border: none;
        width: 150px;
        &:hover{
            transform: scale(1.1);
            transition: ease-in 0.3s;
        }
    }

}
#button{
    display: flex;
    margin-top: 20px;
    background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
    border-radius: 40px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border: none;
    width: 150px;
    &:hover{
        transform: scale(1.1);
        transition: ease-in 0.3s;
    }
}
.no-profile{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89vh; 
    flex-direction: column;
    background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);
}