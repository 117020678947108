.forget{
    background: linear-gradient(135deg, rgba(253, 101, 133, 0.9) 0%, #0D25B9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 89vh;
    .form{
        max-width: 500px;
        h2{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
            font-weight: 700;
        }
        button{
                   display: flex;
                    width: 150px;
                    margin-top: 20px;
                    background: linear-gradient(90deg, rgba(57, 30, 30, 0.7) 0%, #1E50CF 100%);
                    border-radius: 20px;
                    height: 40px;
                    align-items: center;
                    justify-content: center;
                    border: none;
        }
    }
}
