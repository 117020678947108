.ezy__epgrid12 {
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    overflow: hidden;
    position: relative;
    padding: 60px 0;
    z-index: 1;
  
    @media (min-width: 768px) {
      padding: 100px 0;
    }
  }
  
  .gray {
    background-color: rgb(246, 246, 246);
  }
  
  .ezy__epgrid12-item {
    background-color: var(--ezy-card-bg);
    box-shadow: var(--ezy-card-shadow);
   border-radius: 10px; 
    border: 1px solid var(--ezy-border-color);
  }
  
  .ezy__epgrid12-img {
    background-color: var(--ezy-img-bg);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 265px;
  }
  
  .ezy__epgrid12-item-badge {
    background-color: #3fc9ff;
    color: #fff;
    position: absolute;
    top: 15px;
    left: 0;
    border-radius: 0 7px 7px 0;
  }
  
  .ezy__epgrid12-fav-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: var(--ezy-card-bg);
    color: var(--bs-body-color);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .ezy__epgrid12-content a {
    text-decoration: none;
    color: var(--bs-body-color);
    transition: 0.4s;
  
    &:hover {
      color: var(--ezy-theme-color);
    }
  }
  
  .ezy__epgrid12-content h6 {
    font-size: 17px;
  }
  
  .ezy__epgrid12-rating {
    font-size: 14px;
    color: #ffd451;
  }
  
  .ezy__epgrid12-btn {
    color: #fff;
    padding: 13px 35px;
    line-height: 1;
    background-color: var(--ezy-theme-color);
    border-radius: 7px;
  
    &:hover {
      background-color: rgba(var(--ezy-theme-color-rgb), 0.9);
    }
  }
  .imagestylevesd{
    width: 100%;
    height: 180px;
    border-radius: 2px;
    object-fit: contain;
  }